import { TuiValueChangesException, TuiOwnerDocumentException, TuiValuePresentException } from '@taiga-ui/cdk/exceptions';
import { Observable, fromEvent, merge, concat, pipe, timer, EMPTY } from 'rxjs';
import { startWith, take, map, endWith, takeWhile, repeat, switchMap, distinctUntilChanged, shareReplay, filter, ignoreElements, withLatestFrom, tap } from 'rxjs/operators';
import { tuiIsPresent, tuiGetOriginalArrayFromQueryList } from '@taiga-ui/cdk/utils/miscellaneous';
import { ALWAYS_FALSE_HANDLER } from '@taiga-ui/cdk/constants';
import { tuiIsFalsy } from '@taiga-ui/cdk/utils';
import { tuiIsNativeFocused } from '@taiga-ui/cdk/utils/focus';

/**
 * Turns AbstractControl/Abstract-control-directive valueChanges into ReplaySubject(1)
 */
function tuiControlValue(control) {
    return new Observable(subscriber => {
        if (!control.valueChanges) {
            throw new TuiValueChangesException();
        }
        control.valueChanges.pipe(startWith(control.value)).subscribe(subscriber);
    });
}

function tuiTypedFromEvent(target, event, options = {}) {
    /**
     * @note:
     * in RxJS 7 type signature `TuiTypedEventTarget<E>` !== `HasEventTargetAddRemove<E>`
     */
    return fromEvent(target, event, options);
}

/**
 * Letting go of the mouse after it was pressed
 * @param target
 */
function tuiMouseDragFinishFrom(target) {
    return merge(tuiTypedFromEvent(target, 'mouseup'), tuiTypedFromEvent(target, 'dragend'));
}

// TODO: change type in v4.0
// eslint-disable-next-line no-restricted-syntax
var TuiDragStage;
(function (TuiDragStage) {
    TuiDragStage[TuiDragStage["Start"] = 0] = "Start";
    TuiDragStage[TuiDragStage["Continues"] = 1] = "Continues";
    TuiDragStage[TuiDragStage["End"] = 2] = "End";
})(TuiDragStage || (TuiDragStage = {}));
class TuiDragState {
    constructor(stage, event) {
        this.stage = stage;
        this.event = event;
    }
}
function tuiDragAndDropFrom(element) {
    const { ownerDocument } = element;
    if (!ownerDocument) {
        throw new TuiOwnerDocumentException();
    }
    return concat(tuiTypedFromEvent(element, 'mousedown').pipe(take(1), map(event => new TuiDragState(TuiDragStage.Start, event))), merge(tuiTypedFromEvent(ownerDocument, 'mousemove').pipe(map(event => new TuiDragState(TuiDragStage.Continues, event))), tuiMouseDragFinishFrom(ownerDocument).pipe(take(1), map(event => new TuiDragState(TuiDragStage.End, event)), endWith(null))).pipe(takeWhile(tuiIsPresent))).pipe(repeat());
}

/**
 * Operator to set lifespan after which current value is considered obsolete
 */
function tuiIsAlive(lifespan = 0) {
    return pipe(switchMap(() => timer(lifespan).pipe(map(ALWAYS_FALSE_HANDLER), startWith(true))), distinctUntilChanged());
}

let documentMouseUpIsAlive$;
let documentMouseDownIsAlive$;
function tuiFocusVisibleObservable(element) {
    const elementBlur$ = tuiTypedFromEvent(element, 'blur');
    const { ownerDocument } = element;
    if (!ownerDocument) {
        throw new TuiOwnerDocumentException();
    }
    if (!documentMouseDownIsAlive$ || !documentMouseUpIsAlive$) {
        documentMouseUpIsAlive$ = tuiTypedFromEvent(ownerDocument, 'mouseup', {
            capture: true,
        }).pipe(tuiIsAlive(), startWith(false), shareReplay({ bufferSize: 1, refCount: true }));
        documentMouseDownIsAlive$ = tuiTypedFromEvent(ownerDocument, 'mousedown', {
            capture: true,
        }).pipe(tuiIsAlive(), startWith(false), shareReplay({ bufferSize: 1, refCount: true }));
    }
    return merge(
    // focus events excluding ones that came right after mouse action
    concat(tuiTypedFromEvent(element, 'focus').pipe(take(1)), 
    // filtering out blur events when element remains focused so that we ignore browser tab focus loss
    elementBlur$.pipe(filter(() => !tuiIsNativeFocused(element)), take(1), ignoreElements())).pipe(repeat(), withLatestFrom(documentMouseDownIsAlive$, documentMouseUpIsAlive$, (_event, elementActual, documentActual) => elementActual || documentActual), filter(tuiIsFalsy))).pipe(switchMap(() => elementBlur$.pipe(map(ALWAYS_FALSE_HANDLER), take(1), startWith(true))), distinctUntilChanged());
}

function tuiIfMap(project, predicate = Boolean) {
    return pipe(switchMap(value => (predicate(value) ? project(value) : EMPTY)));
}

function tuiIsObserved(observable) {
    var _a, _b;
    return 'observed' in observable
        ? observable.observed
        : !!((_b = (_a = observable) === null || _a === void 0 ? void 0 : _a.observers) === null || _b === void 0 ? void 0 : _b.length);
}

/**
 * Converts changes observable of a QueryList to an Observable of arrays
 */
function tuiQueryListChanges(queryList) {
    return queryList.changes.pipe(startWith(null), map(() => tuiGetOriginalArrayFromQueryList(queryList)));
}
/**
 * @deprecated An alias, use {@link tuiQueryListChanges} instead
 */
const tuiItemsQueryListObservable = tuiQueryListChanges;

function tuiMustBePresent() {
    return map(value => {
        if (!tuiIsPresent(value)) {
            throw new TuiValuePresentException();
        }
        return value;
    });
}

function tuiPressedObservable(element, { onlyTrusted } = { onlyTrusted: true }) {
    const { ownerDocument } = element;
    if (!ownerDocument) {
        throw new TuiOwnerDocumentException();
    }
    return tuiTypedFromEvent(element, 'mousedown').pipe(filter(({ isTrusted }) => isTrusted || !onlyTrusted), switchMap(() => tuiMouseDragFinishFrom(ownerDocument).pipe(map(ALWAYS_FALSE_HANDLER), take(1), startWith(true))));
}

function tuiPreventDefault() {
    return tap(event => event.preventDefault());
}

/**
 * Normalizes scroll event in case element is `html` (document.documentElement)
 */
function tuiScrollFrom(element) {
    return tuiTypedFromEvent(element === element.ownerDocument.documentElement
        ? element.ownerDocument
        : element, 'scroll');
}

function tuiStopPropagation() {
    return tap(e => {
        e.stopPropagation();
    });
}

function tuiWatch(cdr) {
    return tap(() => {
        cdr.markForCheck();
    });
}

function tuiZonefull(zone) {
    return source => new Observable(subscriber => source.subscribe({
        next: value => zone.run(() => subscriber.next(value)),
        error: (error) => zone.run(() => subscriber.error(error)),
        complete: () => zone.run(() => subscriber.complete()),
    }));
}
function tuiZonefree(zone) {
    return source => new Observable(subscriber => zone.runOutsideAngular(() => source.subscribe(subscriber)));
}
function tuiZoneOptimized(zone) {
    return pipe(tuiZonefree(zone), tuiZonefull(zone));
}

/**
 * Generated bundle index. Do not edit.
 */

export { TuiDragStage, TuiDragState, tuiControlValue, tuiDragAndDropFrom, tuiFocusVisibleObservable, tuiIfMap, tuiIsAlive, tuiIsObserved, tuiItemsQueryListObservable, tuiMouseDragFinishFrom, tuiMustBePresent, tuiPressedObservable, tuiPreventDefault, tuiQueryListChanges, tuiScrollFrom, tuiStopPropagation, tuiTypedFromEvent, tuiWatch, tuiZoneOptimized, tuiZonefree, tuiZonefull };

